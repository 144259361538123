
import { defineComponent, reactive, toRefs, onBeforeMount, getCurrentInstance } from 'vue'
import { allDept, updateStatuDept, addDept, deleteDept } from '@/apis/user'
import { ElMessage } from 'element-plus'

interface RoleData {
    id: number
    parentid: string|number
    deptname: string
    leader: string
    phone: string|number
    email: string|number
    status: any
    remark: string
    address: string
    subsidiary?: any[]
     hasChildren?: boolean
}
interface Dept {
    deptparent: number
    deptname: string|number
    deptphone: string|number
    deptemail: string
    deptstatus: string|number
    deptaddress: string|number
    deptremark: any
    deptleader: string
}
const defaultRole: Dept = {
  deptparent: 0,
  deptname: '我的',
  deptphone: '19834535310',
  deptemail: 'qq.com',
  deptstatus: '0',
  deptaddress: '布吉岛',
  deptremark: '测试',
  deptleader: '张鹏来'
}
export default defineComponent({
  setup() {
    // const frontUpload = ref('')
    const { ctx } = getCurrentInstance() as any
    const state = reactive({
      dialogVisible: false,
      dialogType: 'new',
      addDept: Object.assign({}, defaultRole),
      userList: [] as RoleData[],
      tableDataProps: {
        value: 'id',
        label: 'deptname',
        children: 'children',
        checkStrictly: true,
        emitPath: false,
        expandTrigger: 'click'
      },
      getAllUser: async() => {
        await allDept().then((res: any) => {
          if (res.code === 0) {
            state.userList = res?.data
          } else {
            ctx.$message({
              type: 'danger',
              message: '暂无数据'
            })
          }
        })
      },
      handleUpdate: (scope: any) => {
        const { row } = scope
        updateStatuDept({ enok: row.status === 0 ? 1 : 0, did: row.id }).then((res: any) => {
          if (res.code === 0) {
            ctx.$message({
              type: 'success',
              message: '修改成功'
            })
            state.getAllUser()
          } else {
            ctx.$message({
              type: 'success',
              message: '修改成功'
            })
          }
        })
      },
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
        console.log(state.dialogVisible)
      }
    })
    const submitDept = async() => {
      console.log(1)
      await addDept({ ...state.addDept }).then((res: any) => {
        console.log(res)
      })
    }
    const deptDelete = async(scope: any) => {
      console.log(scope.row.id)
      const id = scope.row.id
      await deleteDept({ did: id }).then((res: any) => {
        ElMessage({
          type: 'success',
          message: res?.msg
        })
      })
    }
    onBeforeMount(() => {
    //   getServiceRoutes()
      state.getAllUser()
    })
    return {
      ...toRefs(state),
      submitDept,
      deptDelete
    }
  }
})
