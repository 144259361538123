<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        size="mini"
        type="primary"
        @click="handleCreateRole"
      >
        {{ "添加部门" }}
      </el-button>
    </div>
    <el-table
      :data="userList"
      style="width:100%;margin-top:30px;"
      highlight-current-row
      row-key="id"
      border
      lazy
      default-expand-all
      :load="load"
      :tree-props="{subsidiary: 'subsidiary', hasChildren: 'hasChildren'}"
    >
      <el-table-column
        align="center"
        label="企业名"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.deptname }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="负责人"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.leader }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="所属权限"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.parentid }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="电话"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.phone }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="状态"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.status === 0 ? "禁用" : "启用" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="描述"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.remark }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="地址"
        show-overflow-tooltip="true"
      >
        <template #default="{row}">
          {{ row.address }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
      >
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleUpdate(scope)"
          >
            {{ scope.row.status === 1 ? "禁用":"启用" }}
          </el-button>
          <el-button
            type="danger"
            size="mini"
            @click="deptDelete(scope)"
          >
            {{ "删除" }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      v-model="dialogVisible"
      width="22rem"
      :title="dialogType === 'edit' ? '修改企业' : '添加企业'"
    >
      <el-form
        :model="addDept"
        label-width="60px"
        label-position="left"
      >
        <el-form-item label="部门">
          <el-cascader
            v-model="addDept.deptparent"
            size="mini"
            :options="userList"
            clearable
            :props="tableDataProps"
          />
        </el-form-item>
        <el-form-item label="企业名">
          <el-input
            size="mini"
            v-model="addDept.deptname"
            placeholder="企业名"
          />
        </el-form-item>
        <el-form-item label="领导人">
          <el-input
            size="mini"
            v-model="addDept.deptleader"
            placeholder="密码"
          />
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            size="mini"
            v-model="addDept.deptemail"
            placeholder="邮箱"
          />
        </el-form-item>
        <el-form-item label="地址">
          <el-input
            size="mini"
            v-model="addDept.deptaddress"
            placeholder="地址"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            v-model="addDept.deptstatus"
            active-text="启用"
            inactive-text="禁用"
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            size="mini"
            v-model="addDept.deptremark"
            placeholder="描述"
          />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            size="mini"
            v-model="addDept.deptphone"
            placeholder="手机号"
          />
        </el-form-item>
      </el-form>
      <div style="text-align:center;">
        <el-button
          size="mini"
          type="danger"
          @click="dialogVisible = false"
        >
          {{ "返回" }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="submitDept"
        >
          {{ "确认" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, onBeforeMount, getCurrentInstance } from 'vue'
import { allDept, updateStatuDept, addDept, deleteDept } from '@/apis/user'
import { ElMessage } from 'element-plus'

interface RoleData {
    id: number
    parentid: string|number
    deptname: string
    leader: string
    phone: string|number
    email: string|number
    status: any
    remark: string
    address: string
    subsidiary?: any[]
     hasChildren?: boolean
}
interface Dept {
    deptparent: number
    deptname: string|number
    deptphone: string|number
    deptemail: string
    deptstatus: string|number
    deptaddress: string|number
    deptremark: any
    deptleader: string
}
const defaultRole: Dept = {
  deptparent: 0,
  deptname: '我的',
  deptphone: '19834535310',
  deptemail: 'qq.com',
  deptstatus: '0',
  deptaddress: '布吉岛',
  deptremark: '测试',
  deptleader: '张鹏来'
}
export default defineComponent({
  setup() {
    // const frontUpload = ref('')
    const { ctx } = getCurrentInstance() as any
    const state = reactive({
      dialogVisible: false,
      dialogType: 'new',
      addDept: Object.assign({}, defaultRole),
      userList: [] as RoleData[],
      tableDataProps: {
        value: 'id',
        label: 'deptname',
        children: 'children',
        checkStrictly: true,
        emitPath: false,
        expandTrigger: 'click'
      },
      getAllUser: async() => {
        await allDept().then((res: any) => {
          if (res.code === 0) {
            state.userList = res?.data
          } else {
            ctx.$message({
              type: 'danger',
              message: '暂无数据'
            })
          }
        })
      },
      handleUpdate: (scope: any) => {
        const { row } = scope
        updateStatuDept({ enok: row.status === 0 ? 1 : 0, did: row.id }).then((res: any) => {
          if (res.code === 0) {
            ctx.$message({
              type: 'success',
              message: '修改成功'
            })
            state.getAllUser()
          } else {
            ctx.$message({
              type: 'success',
              message: '修改成功'
            })
          }
        })
      },
      handleCreateRole: () => {
        state.dialogType = 'new'
        state.dialogVisible = true
        console.log(state.dialogVisible)
      }
    })
    const submitDept = async() => {
      console.log(1)
      await addDept({ ...state.addDept }).then((res: any) => {
        console.log(res)
      })
    }
    const deptDelete = async(scope: any) => {
      console.log(scope.row.id)
      const id = scope.row.id
      await deleteDept({ did: id }).then((res: any) => {
        ElMessage({
          type: 'success',
          message: res?.msg
        })
      })
    }
    onBeforeMount(() => {
    //   getServiceRoutes()
      state.getAllUser()
    })
    return {
      ...toRefs(state),
      submitDept,
      deptDelete
    }
  }
})
</script>
